<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="6" lg="6" cols="12">
          <b-card-actions
              class="list-card"
              ref="first-table"
              @refresh="refreshStop('list')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">{{ depoName }} deposuna ait veriler</h4>
                </b-col>
              </div>
            </b-row>
            <b-tabs class="mt-5">
              <b-tab active>
                <template #title>
                  <font-awesome-icon icon="fa-solid fa-layer-group"/>
                  <span>Depoya Ait Stok Listesi</span>
                </template>
                <div class="d-flex justify-content-between flex-wrap table-top">
                  <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                    <b-input-group
                        size="sm"
                        prepend="Gösterilecek kayıt sayısı"
                        class="unPrint"
                    >
                      <b-form-select
                          id="per-page-select"
                          v-model="firstPerPage"
                          :options="pageOptions"
                          @change="firstPerPageChange($event)"
                          size="sm"
                      >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                  <b-input-group size="sm" class="search-bar">
                    <b-input-group-prepend>
                      <b-button
                          variant="outline-primary"
                          size="sm"
                          disabled
                          class="search-input mb-0"
                      >
                        Ara
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input id="filterInput" v-model="firstFilter" type="search"/>
                  </b-input-group>
                </div>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative depo-detay-stok-list mt-1"
                    :items="listGet"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                  <template #cell(stokAdi)="data">
                    <strong class="ml-50">
                      {{ data.item.stokAdi }}
                    </strong>
                  </template>
                  <template #cell(adet)="data">
                    <strong class="ml-50">
                      <b-badge
                          id="bakiye-badge"
                          :variant="data.item.adet < 10 ? 'danger' : data.item.adet < 20 ? 'warning':'success'  "
                          v-if="data.item.adet"
                      >
                        {{ data.item.adet }}
                      </b-badge>
                    </strong>
                  </template>
                </b-table>
                <hr class="mt-50"/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ firstTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="firstCurrentPage"
                        @change="firstPageChange($event)"
                        :total-rows="firstTotalRows"
                        :per-page="firstPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab @click="islemGecmisi">
                <template #title>
                  <font-awesome-icon icon="fa-solid fa-arrow-rotate-left"/>
                  <span>Depo İşlem Geçmişi</span>
                </template>
                <div class="d-flex justify-content-between flex-wrap table-top">
                  <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                    <b-input-group
                        size="sm"
                        prepend="Gösterilecek kayıt sayısı"
                        class="unPrint"
                    >
                      <b-form-select
                          id="per-page-select"
                          v-model="secondPerPage"
                          :options="pageOptions"
                          @change="secondPerPageChange($event)"
                          size="sm"
                      >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                  <b-input-group size="sm" class="search-bar">
                    <b-input-group-prepend>
                      <b-button
                          variant="outline-primary"
                          size="sm"
                          disabled
                          class="search-input mb-0"
                      >
                        Ara
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input id="filterInput" v-model="secondFilter" type="search"/>
                  </b-input-group>
                </div>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative depo-detay-stok-list mt-1"
                    :items="depoAktarim"
                    :fields="aktarimFields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="aktarimDetay"
                >
                  <template #cell(aktarimID)="data">
                    <strong class="ml-50">
                      <b-badge variant="primary">
                        {{ data.item.aktarimID }}
                      </b-badge>
                    </strong>
                  </template>
                  <template #cell(user)="data">
                    <strong class="ml-50">
                      {{ data.item.userName }}
                    </strong>
                  </template>
                </b-table>
                <hr class="mt-50"/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ secondTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="secondCurrentPage"
                        @change="secondPageChange($event)"
                        :total-rows="secondTotalRows"
                        :per-page="secondPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <template #title>
                  <font-awesome-icon :icon="['fas', 'box-archive']"/>
                  <span>Aktarım Filtrele</span>
                </template>
                <div class="d-flex justify-content-between flex-wrap table-top">
                  <b-form-group label="Başlangıç Tarihi" label-for="basicInput"
                                class="d-inline-block"
                  >
                    <flat-pickr v-model="filtre.baslangic" class="form-control"/>
                  </b-form-group>
                  <b-form-group label="Bitiş Tarihi" label-for="basicInput"
                                class="d-inline-block"
                  >
                    <flat-pickr v-model="filtre.bitis" class="form-control"/>
                  </b-form-group>
                </div>
                <b-row>
                  <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="float-right mt-1"
                        v-b-modal.fis-modal
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-print" class="mr-50"/>
                      Çıktısını Al
                    </b-button>
                  </b-col>
                </b-row>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative depo-detay-stok-list mt-1"
                    :items="altarimFiltre"
                    :fields="aktarimFiltreFields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                >
                  <template #cell(stokAdi)="data">
                    <strong>
                      {{ data.item.stokAdi }}
                    </strong>
                  </template>
                  <template #cell(birim)="data">
                    <p class="m-0">
                      {{ data.item.birim }}
                    </p>
                  </template>
                  <template #cell(adet)="data">
                    <p class="m-0">
                      {{ data.item.adet }}
                    </p>
                  </template>
                  <template #cell(araToplam)="data">
                    <p class="m-0">
                      {{ data.item.araToplam }} ₺
                    </p>
                  </template>
                  <template #cell(toplamVergi)="data">
                    <p class="m-0">
                      {{ data.item.toplamVergi }} ₺
                    </p>
                  </template>
                  <template #cell(toplamTutar)="data">
                    <strong>
                      {{ data.item.toplamTutar }} ₺
                    </strong>
                  </template>
                  <template #cell(kayitTarih)="data">
                    <p class="m-0">
                      {{ data.item.kayitTarih.slice(0, 16) }}
                    </p>
                  </template>
                </b-table>
                <hr class="mt-50"/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ thirdTotalRows }} Kayıt bulundu</p>
                  </b-col>
                </b-row>
                <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="false"
                    :preview-modal="true"
                    :paginate-elements-by-height="1400"
                    filename="Fatura ve Hizmet Karşılığı Yapılan Ödeme Tutanağı"
                    :pdf-quality="pdfQuality"
                    :manual-pagination="false"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    pdf-content-width="800px"
                    ref="cikis-tutanagi"
                >
                  <section slot="pdf-content" class=" pl-2 pr-2 font-size-12" ref="cikis-tutanagi">
                    <b-row class="mb-3">
                      <b-col cols="12" class="text-center mt-5">
                        <h3 class="font-size-16">{{ $store.getters.FIRMA_BILGILERI.tamUnvan }}</h3>
                        <h4 class="font-size-15">İŞ DAĞITIM VE MUAYENE ÇIKIŞ TUTANAĞI</h4>
                      </b-col>
                      <b-col cols="12" class="text-center mt-3"
                             style="display: flex;justify-content: space-between;font-weight: bold;"
                      >
                        <p>Fiş No : {{ fisNo }}</p>
                        <p>Fiş Tarihi : {{ filtre.baslangic }} / {{ filtre.bitis }} </p>
                      </b-col>
                      <b-col cols="12" class="mt-1" style="padding-left: 30px;padding-right: 30px;">
                        <b-row class="text-center">
                          <b-col xl="5" lg="5" md="5" xs="12" style="border: 1px solid black;">
                            Malın Cinsi
                          </b-col>
                          <b-col xl="1" lg="1" md="3" xs="12" style="border: 1px solid black;border-left: none;">
                            Miktar
                          </b-col>
                          <b-col xl="1" lg="1" md="3" xs="12" style="border: 1px solid black;border-left: none;">
                            Birim
                          </b-col>
                          <b-col xl="2" lg="2" md="3" xs="12" style="border: 1px solid black;border-left: none;">
                            Birim Fiyat
                          </b-col>
                          <b-col xl="1" lg="1" md="3" xs="12" style="border: 1px solid black;border-left: none;">
                            Kdv
                          </b-col>
                          <b-col xl="2" lg="2" md="3" xs="12" style="border: 1px solid black;border-left: none;">
                            Tutar
                          </b-col>
                        </b-row>
                        <b-row class="text-center"
                               style="font-weight: bold;height: 450px;display: flow;border: 1px solid black;border-top: none;border-bottom: none"
                        >
                          <div v-for="item in filtreDetay.stoklar" :key="item.depoStokID" class="d-flex">
                            <b-col xl="5" lg="5" md="5" xs="12"
                                   style="border-right: 1px solid black;border-bottom: 1px solid black;"
                            >
                              {{ item.stokAdi }}
                            </b-col>
                            <b-col xl="1" lg="1" md="3" xs="12"
                                   style="border-right: 1px solid black;border-bottom: 1px solid black;"
                            >
                              {{ item.adet }}
                            </b-col>
                            <b-col xl="1" lg="1" md="3" xs="12"
                                   style="border-right: 1px solid black;border-bottom: 1px solid black;"
                            >
                              {{ item.birim }}
                            </b-col>
                            <b-col xl="2" lg="2" md="3" xs="12"
                                   style="border-right: 1px solid black;border-bottom: 1px solid black;"
                            >
                              {{ item.tutar }}
                            </b-col>
                            <b-col xl="1" lg="1" md="3" xs="12"
                                   style="border-right: 1px solid black;border-bottom: 1px solid black;"
                            >
                              {{ item.kdv }}
                            </b-col>
                            <b-col xl="2" lg="2" md="3" xs="12" style="border-bottom: 1px solid black;">
                              {{ item.toplamTutar }}
                            </b-col>
                          </div>
                        </b-row>
                        <b-row class="text-center"
                               style="font-weight: bold;border-top:1px solid black;"
                        >
                          <b-col xl="3" lg="3" md="3" xs="12"
                                 style="border: 1px solid black;border-bottom: none;border-top: none;place-content: center;border-right: none;"
                          >
                            Yalnız, {{ tlCevir(parseFloat(finance().toplamTutar)) }}
                          </b-col>
                          <b-col xl="1" lg="1" md="3" xs="12"
                                 style="border: 1px solid black;border-left: none;border-bottom: none;border-top: none;border-right: none;"
                          >
                            &nbsp;
                          </b-col>
                          <b-col xl="1" lg="1" md="3" xs="12"
                                 style="border: 1px solid black;border-left: none;border-bottom: none;border-top: none;border-right: none;"
                          >
                            &nbsp;
                          </b-col>
                          <b-col xl="2" lg="2" md="3" xs="12"
                                 style="border: 1px solid black;border-left: none;border-bottom: none;border-top: none;border-right: none"
                          >
                            &nbsp;
                          </b-col>
                          <b-col xl="3" lg="3" md="3" xs="12" style="border-right: 1px solid black;">
                            <p class="m-0">Ara Toplam :</p>
                            <p class="m-0">Kdv Tutarı :</p>
                            <p class="m-0">Toplam Tutar :</p>
                          </b-col>
                          <b-col xl="2" lg="2" md="3" xs="12"
                                 style="border: 1px solid black;border-left: none;border-bottom: none;border-top: none;"
                          >
                            <p class="m-0">{{ finance().araToplam }}</p>
                            <p class="m-0">{{ finance().kdvTutari }}</p>
                            <p class="m-0">{{ finance().toplamTutar }}</p>
                          </b-col>
                        </b-row>
                        <b-row class="text-center"
                               style="font-weight: bold;border-top:1px solid black;border-right: 1px solid black;border-left: 1px solid black;"
                        >
                          <b-col cols="3" v-for="item in semaListesi" :key="item.uyeID"
                                 style="padding:20px"
                          >
                            <p class="m-0" style="text-decoration: underline;"> {{ item.pozisyon }} </p>
                            <p class="m-0">{{ item.adSoyad }}</p>
                            <p class="m-0">{{ item.unvan }}</p>
                          </b-col>
                        </b-row>
                        <b-row class="text-center"
                               style="font-weight: bold;border-bottom: 1px solid black;border-right: 1px solid black"
                        >
                          <b-col cols="12" style="border-left: 1px solid black;padding:20px;" class="text-left">
                            <p>Yukarıda belirtilen iş yada malzemelerin muayene ve kontrolleri yapılmış olup,
                              <br>
                              <strong style="color:black">{{ depoBilgileri.depoAdi }}</strong>
                              <br>
                              adına teslim ve çıkışı yapılmıştır.</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </section>
                </vue-html2pdf>
              </b-tab>
            </b-tabs>
          </b-card-actions>
        </b-col>
        <b-col xl="6" lg="6" cols="12">
          <b-card-actions
              class="list-card"
              ref="second-table"
              @refresh="refreshStop('list')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Yeni Stok Aktarımı</h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-5">
              <b-row>
                <b-col cols="12">
                  <table class="table table-sm table-striped">
                    <thead>
                    <tr>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle || '#3c6e71' +'!important',color:'white!important'}"
                          scope="col"
                      >Ürün Adı
                      </th>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle || '#3c6e71' +'!important',color:'white!important'}"
                          scope="col"
                      >Stok Miktarı
                      </th>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle || '#3c6e71' +'!important',color:'white!important'}"
                          scope="col"
                      >Miktar
                      </th>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle || '#3c6e71' +'!important',color:'white!important'}"
                          scope="col"
                      >Birim
                      </th>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle || '#3c6e71' +'!important',color:'white!important'}"
                          scope="col"
                      />
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(urun,index) in urun" :key="urun.id">
                      <td style="width: 45%">
                        <v-select
                            v-model="urun.stokAdi"
                            @search="fetchOptions"
                            :options="stokListesi"
                            label="stokAdi"
                            class="style-chooser"
                            no-options-text="Seçenek Yok"
                            @input="yeniStok($event , index)"
                        >
                          <template #selected-option-container="{ option }">
                            <div class="vs__selected v-select-selected-xl" v-if="window.width > 1320">
                              {{
                                option.stokAdi.length > 50 ? option.stokAdi.slice(0, 50) + '...' : option.stokAdi
                              }}
                            </div>
                            <div v-else-if="window.width <= 1320 && window.width > 850">
                              {{
                                option.stokAdi.length > 15 ? option.stokAdi.slice(0, 15) + '...' : option.stokAdi
                              }}
                            </div>
                            <div v-else-if="window.width <= 850 && window.width > 665">
                              {{
                                option.stokAdi.length > 10 ? option.stokAdi.slice(0, 10) + '...' : option.stokAdi
                              }}
                            </div>
                            <div v-else-if="window.width <= 665">
                              {{
                                option.stokAdi.length > 5 ? option.stokAdi.slice(0, 5) + '...' : option.stokAdi
                              }}
                            </div>
                          </template>
                        </v-select>
                      </td>
                      <td style="width: 20%">
                        <b-form-input
                            v-if="urun.stokAdi != undefined"
                            v-model="urun.stokAdi.adet"
                            type="number"
                            disabled
                            autocomplete="off"
                            size="sm"
                        />
                      </td>
                      <td style="width: 20%">
                        <b-form-input
                            type="number"
                            v-model="urun.miktar"
                            autocomplete="off"
                            size="sm"
                        />
                      </td>
                      <td style="width: 20%">
                        <b-form-select v-model="urun.birim" :options="birimOptions" placeholder="birim türünü seçiniz"
                                       autocomplete="off" size="sm"
                        />
                      </td>
                      <td style="width: 1%">
                        <b-button
                            variant="gradient-danger"
                            class="btn-icon rounded-circle"
                            size="sm"
                            v-b-tooltip.right="'Satırı Sil'"
                            @click="satirSil(urun.id)"
                            :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger}"
                        >
                          <font-awesome-icon icon="fa-solid fa-trash-can"/>
                        </b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col class="mt-2 text-right">
                  <hr class="mt-0"/>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1 float-left"
                      @click="repeateAgain"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-plus" class="mr-50"/>
                    Satır Ekle
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="success"
                      class="mr-1"
                      @click="stokAktar"
                      :disabled="setTime"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-right-left" class="mr-50"/>
                    Stok Aktar
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="secondary"
                      class="mr-1"
                      @click="reset"
                  >
                    Sıfırla
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="new"
        ok-only
        hide-footer
        hide-header
        centered
        size="lg"
        header-bg-variant="white"
        no-close-on-esc
        no-close-on-backdrop
    >
      <b-row>
        <b-col cols="12">
          <h3>
            <b-badge variant="success" class="modalBadge">
              Yeni Stok Kaydı
            </b-badge>
          </h3>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Adı" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.name" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Grubu" label-for="basicInput">
            <model-list-select
                class=""
                v-model="addItem.grupID"
                :list="stokGrupGet"
                option-value="stokGrupID"
                option-text="grupAdi"

                placeholder="Stok Grubunu Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Alış Fiyat" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="addItem.alisFiyat" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim Fiyatı" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="addItem.birimFiyat" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="KDV" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.kdv" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim" label-for="basicInput">
            <b-form-select v-model="addItem.birim" :options="birimOptions" placeholder="Stok Grubunu Seçiniz"/>
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mt-1">
          <b-form-group label="Barkod" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.barkod" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group :label="addItem.birim" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.adet" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="mt-1">
          <b-form-group id="stoksuz-satis-label" label="Stoksuz Satış" label-for="basicInput">
            <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
                autocomplete="off"
                v-model="addItem.stoksuzSatis"
            >
              <span class="switch-icon-left">
                <font-awesome-icon icon="fa-solid fa-check"/>
              </span>
              <span class="switch-icon-right">
                    <font-awesome-icon icon="fa-solid fa-xmark"/>
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
              @click="add"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-50"
          >
            Kaydet
          </b-button>
          <b-button
              @click="newReset"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              class="mr-50"
          >
            Sıfırla
          </b-button>
          <b-button
              @click="newHide"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
          >
            Kapat
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-sidebar
        id="add-stok-grup"
        :visible="isNewGroup"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        right
        @change="(val) => $emit('update:is-add-stok-grup-active', val)"
        ref="newGrup"
    >
      <template>
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Yeni Stok Grubu Ekle
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="newGroupCancel"
          />
        </div>
        <b-form @submit.prevent id="sidebar-form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Grup Adı"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupName"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="!grupName"
                  @click="grupSave('kayit')"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                Kaydet
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="newGroupCancel"
              >
                Vazgeç
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
    <b-modal
        id="fis-modal"
        centered
        title="Fiş No Giriniz"
        ok-only
        hide-footer
    >
      <b-row>
        <b-col :span="12">
          <b-form-group
              label="Fiş No"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                placeholder="Fiş No Giriniz"
                autocomplete="off"
                v-model="fisNo"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="false" :span="6">
          <b-form-group
              label="Fiş Tarihi"
              label-for="basicInput"
          >
            <flat-pickr
                v-model="fisTarihi"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :span="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon float-right unPrint mt-2"
              @click="download('cikis-tutanagi')"
          >
            <feather-icon icon="ArchiveIcon" class="mr-50"/>
            İndir Yada Yazdır
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BSidebar,
  BTab,
  BTabs,
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vMultiselectListbox from 'vue-multiselect-listbox-v2'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'
import _ from 'lodash'
import vSelect from 'vue-select'
import axios from 'axios'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'
import VueHtml2pdf from 'vue-html2pdf'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  name: 'DepoDetay',
  components: {
    VueHtml2pdf,
    flatPickr,
    BSidebar,
    BTab,
    vSelect,
    BTabs,
    BContainer,
    vMultiselectListbox,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      filtreDetay: {
        stoklar: [],
      },
      filtre: {
        baslangic: '',
        bitis: '',
      },
      aktarimFiltreFields: [
        {
          key: 'stokAdi',
          label: 'Ürün Adı',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'birim',
          label: 'Birim',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'adet',
          label: 'Adet',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'araToplam',
          label: 'Ara Toplam',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'toplamVergi',
          label: 'Toplam Vergi',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'toplamTutar',
          label: 'Toplam Tutar',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'kayitTarih',
          label: 'İşlem Tarihi',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
      ],
      aktarimFields: [
        {
          key: 'aktarimID',
          label: 'ID',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'user',
          label: 'İşlem Sahibi',
          sortable: true,
          thStyle: {
            width: '40%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '40%' },
        },
        {
          key: 'kayitTarih',
          label: 'İşlem Tarihi',
          sortable: true,
          thStyle: {
            width: '25%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
      ],
      fields: [
        {
          key: 'stokAdi',
          label: 'Ürün Adı',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '50%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '50%' },
        },
        {
          key: 'adet',
          label: 'Adet',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'birim',
          label: 'Birim',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
      ],
      fisTarihi: '',
      fisNo: '',
      firstTotalRows: 0,
      firstCurrentPage: 1,
      firstPerPage: 20,
      firstToplamKayit: '',
      firstFilter: null,
      secondTotalRows: 0,
      secondCurrentPage: 1,
      secondPerPage: 20,
      secondToplamKayit: '',
      secondFilter: null,
      thirdTotalRows: 0,
      thirdCurrentPage: 1,
      thirdPerPage: 20,
      thirdToplamKayit: '',
      thirdFilter: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      isNewGroup: false,
      window: {
        width: 0,
        height: 0,
      },
      id: this.$route.params.id,
      depoName: '',
      depoID: '',
      nextTodoId: 2,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      urun: [{
        id: 1,
        stokAdi: '',
        miktar: 0,
        birim: 'Adet',
      }],
      birimOptions: ['Adet', 'Koli', 'Paket', 'Kutu', 'Kg', 'Gram', 'Litre', 'Ton', 'Net Ton', 'Gross Ton', 'Yıl', 'Saat', 'Dakika', 'Saniye', 'mm', 'cm', 'Hücre Adet', 'Cift', 'Set', 'Düzine', 'Brüt Kalori'],
      addItem: {
        name: '',
        grupID: '',
        adet: '',
        birim: 'Adet',
        birimFiyat: '',
        kdv: '',
        alisFiyat: '',
        stoksuzSatis: false,
        barkod: '',
      },
      actionIndex: '',
      grupName: '',
      setTime: false,
    }
  },
  computed: {
    uyeler() {
      return Object.values(store.getters.DEPO_UYELER_GET)
    },
    depoBilgileri() {
      return {
        depoAdi: this.depoName,
      }
    },
    semaListesi() {
      const komisyon = Object.values(store.getters.DEPO_KOMISYON_SEMA_GET)
          .filter(item => item.evrakTuru == 1)
      return komisyon.map(item => {
        const uye = this.uyeler.find(uye => uye.uyeID == item.uyeID)
        return {
          ...uye,
          pozisyon: this.getPozisyonAdi(item.pozisyon),
        }
      })
    },
    altarimFiltre() {
      return Object.values(store.getters.DEPO_DETAY.aktarimDetayFiltre)
    },
    depoAktarim() {
      return Object.values(store.getters.DEPO_DETAY.depoAktarim)
    },
    listGet() {
      return Object.values(store.getters.DEPO_DETAY.depoStokList)
    },
    stokListesi() {
      const grupList = []
      grupList.push(Object.values(store.getters.STOK_LIST_GET))
      grupList[0].push({
        stokAdi: 'Yeni Stok Oluştur',
        stokID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'stokAdi'], ['asc', 'asc'])
    },
    depoListesi() {
      return store.getters.DEPO_LIST_GET
    },
    stokGrupGet() {
      const grupList = []
      grupList.push(Object.values(store.getters.STOK_GROUP_GET))
      grupList[0].push({
        grupAdi: 'Yeni Stok Grubu Oluştur',
        stokGrupID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'grupAdi'], ['asc', 'desc'])
    },
  },
  watch: {
    filtre: {
      deep: true,
      handler() {
        this.aktarimFiltre()
      },
    },
    firstFilter(newVal) {
      this.$refs['first-table'].showLoading = true
      this.$store
          .dispatch('depoDetayStok', {
            uzunluk: this.firstPerPage,
            baslangic: 0,
            searchKey: newVal,
            depoID: this.id,
          })
          .then(res => {
            this.$refs['first-table'].showLoading = false
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.liste?.length
          })
    },
    secondFilter(newVal) {
      this.$refs['first-table'].showLoading = true
      this.$store
          .dispatch('depoDetayAktarim', {
            uzunluk: this.secondPerPage,
            baslangic: 0,
            searchKey: newVal,
            depoID: this.id,
          })
          .then(res => {
            this.$refs['first-table'].showLoading = false
            this.secondToplamKayit = res.liste?.length
            this.secondTotalRows = res.toplamKayit
          })
    },
    addItem: {
      deep: true,
      handler(newVal) {
        if (newVal.grupID == -1) {
          this.$root.$emit('bv::toggle::collapse', 'add-stok-grup')
          this.$bvModal.hide('new')
          this.isNewGroup = true
        }
      },
    },
  },
  methods: {
    download(ref) {
      this.$bvModal.hide('fis-modal')
      this.$refs[ref].generatePdf()
    },
    getPozisyonAdi(a) {
      if (a == 0) return 'Üye'
      if (a == 1) return 'Başkan'
      if (a == 2) return 'Harcama Yetkilisi'
      return ''
    },
    getUyeler() {
      store.dispatch('depoUyeListesi')
    },
    getKomisyonSema() {
      store.dispatch('depoKomisyonSemaListesi')
    },
    finance() {
      return {
        araToplam: _.sumBy(this.filtreDetay.stoklar, function (o) {
          return parseFloat(o.tutar) * parseFloat(o.adet)
        }),
        kdvTutari: _.sumBy(this.filtreDetay.stoklar, function (o) {
          return (parseFloat(o.tutar) * parseFloat(o.kdv) / 100 * parseFloat(o.adet))
        }),
        toplamTutar: _.sumBy(this.filtreDetay.stoklar, function (o) {
          return parseFloat(o.toplamTutar)
        }),
      }
    },
    tlCevir(sayi) {
      sayi = sayi.toString()
          .replace('.', ',')
      // ?  ornek sayi 1800.00
      let rakam = sayi.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          .split(',')
      let tamsayi = rakam[0]
      let ondalik = rakam[1] || ''

      var birler = ['', 'BİR', 'İKİ', 'ÜÇ', 'DÖRT', 'BEŞ', 'ALTI', 'YEDİ', 'SEKİZ', 'DOKUZ']
      var onlar = ['', 'ON', 'YİRMİ', 'OTUZ', 'KIRK', 'ELLİ', 'ALTMIŞ', 'YETMİŞ', 'SEKSEN', 'DOKSAN']
      var binler = ['', 'BİN', 'MİLYON', 'MİLYAR', 'TRİLYON', 'KATRİLYON', 'KENTİLYON']
      var sonuc = []

      var adim = 0
      for (let i = tamsayi.split('.').length; i > 0; i--) {
        sayi = tamsayi.split('.')[i - 1]
        if (sayi.length == 1) {
          sayi = '00' + sayi
        }
        if (sayi.length == 2) {
          sayi = '0' + sayi
        }
        let c = ''

        for (let j = 1; j < sayi.length + 1; j++) {
          if (j == 1 && sayi[j - 1] == 1) {
            c += ' YÜZ '
          } else if (j == 1 && birler[sayi[j - 1]] != '') {
            c += birler[sayi[j - 1]] + ' YÜZ '
          } else if (j == 2) {
            c += onlar[sayi[j - 1]] + ' '
          } else if (j == 3 && tamsayi.length == 5 && sayi[j - 1] == 1 && adim == 1) {
            c += ' '
          } else if (j == 3) {
            c += birler[sayi[j - 1]] + ' '
          }
        }

        if (c != '') {
          sonuc.push(c + binler[adim])
        }
        adim++
      }

      if (sonuc.length != 0) {
        sonuc = sonuc.reverse()
            .join(' ') + ' TL'
      } else {
        sonuc = ''
      }
      if (ondalik.length == 1) {
        ondalik = ondalik + '0'
      }
      if (ondalik != '') {
        sonuc += ' ' + onlar[ondalik[0]] + ' ' + birler[ondalik[1]] + ' KR'
      }

      sonuc = sonuc.replace(/ /g, ' ')
          .trim()
      return sonuc
    },
    aktarimFiltre() {
      store.dispatch('depoDetayAktarimFiltre', {
        baslangic: this.filtre.baslangic,
        bitis: this.filtre.bitis,
        depoID: this.id,
      })
          .then(res => {
            this.filtreDetay.stoklar = res.aktarilanStoklar
            this.thirdTotalRows = res.aktarilanStoklar.length
          })
    },
    aktarimDetay(item) {
      router.push({
        name: 'aktarimDetay',
        params: { id: item.aktarimID },
      })
    },
    islemGecmisi() {
      store.dispatch('depoDetayAktarim', {
        baslangic: 0,
        uzunluk: this.firstPerPage,
        searchKey: '',
        depoID: this.id,
      })
          .then(res => {
            this.secondTotalRows = res.toplamKayit
          })
    },
    secondPageChange(newVal) {
      const veri = newVal * this.secondPerPage - this.secondPerPage
      this.$refs['first-table'].showLoading = true
      this.$store
          .dispatch('depoDetayAktarim', {
            uzunluk: this.secondPerPage,
            baslangic: veri,
            searchKey: this.secondFilter,
            depoID: this.id,
          })
          .then(res => {
            this.$refs['first-table'].showLoading = false
            this.secondToplamKayit = res.liste?.length
            this.secondTotalRows = res.toplamKayit
          })
    },
    secondPerPageChange(newVal) {
      const veri = this.secondCurrentPage * newVal - newVal
      this.$refs['first-table'].showLoading = true
      this.$store
          .dispatch('depoDetayAktarim', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.secondFilter,
            depoID: this.id,
          })
          .then(res => {
            this.$refs['first-table'].showLoading = false
            this.secondToplamKayit = res.liste?.length
            this.secondTotalRows = res.toplamKayit
          })
    },
    firstPageChange(newVal) {
      const veri = newVal * this.firstPerPage - this.firstPerPage
      this.$refs['first-table'].showLoading = true
      this.$store
          .dispatch('depoDetayStok', {
            uzunluk: this.firstPerPage,
            baslangic: veri,
            searchKey: this.firstFilter,
            depoID: this.id,
          })
          .then(res => {
            this.$refs['first-table'].showLoading = false
            this.firstToplamKayit = res.liste?.length
            this.firstTotalRows = res.toplamKayit
          })
    },
    firstPerPageChange(newVal) {
      const veri = this.firstCurrentPage * newVal - newVal
      this.$refs['first-table'].showLoading = true
      this.$store
          .dispatch('depoDetayStok', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.firstFilter,
            depoID: this.id,
          })
          .then(res => {
            this.$refs['first-table'].showLoading = false
            this.firstToplamKayit = res.liste?.length
            this.firstTotalRows = res.toplamKayit
          })
    },
    stokAktar() {
      this.setTime = true
      setTimeout(() => {
        this.setTime = false
      }, 1000)
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'depo')
      fd.append('methodName', 'depoAktarimKayit')
      fd.append('depoID', this.id)
      this.urun.forEach((item, i) => {
        if (item.stokAdi.stokID || item.stokID) {
          fd.append(`stokListesi[${i}][stokID]`, item.stokAdi.stokID || item.stokID)
          fd.append(`stokListesi[${i}][stokAdi]`, item.stokAdi.stokAdi || item.stokAdi)
          fd.append(`stokListesi[${i}][adet]`, item.miktar || 0)
          fd.append(`stokListesi[${i}][birim]`, item.birim)
        }
      })
      if (Array.from(fd).length !== 4) {
        axios.post(store.state.POST_URL, fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
            .then((res, position = 'bottom-right') => {
              if (res.data.result.status == 200) {
                store.dispatch('depoDetayStok', {
                  baslangic: 0,
                  uzunluk: this.perPage,
                  searchKey: '',
                  depoID: this.id,
                })
                    .then(res => {
                      this.totalRows = res.toplamKayit
                      this.firstTotalRows = res.toplamKayit
                    })
                if (this.filtre.baslangic || this.filtre.bitis) this.aktarimFiltre()
                this.reset()
                const mesaj = this.$store.getters.notificationSettings(res.data)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  }, { position })
                }
                store.commit('setToken', res.data.userToken)
              } else {
                const mesaj = this.$store.getters.notificationSettings(res.data)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'warning',
                    },
                  })
                }
              }
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: 'Ürün Seçiniz',
            icon: 'ThumbsDownIcon',
            variant: 'warning',
          },
        })
      }
    },
    satirSil(urunID) {
      const index = this.urun.findIndex(item => item.id == urunID)
      this.urun.splice(index, 1)
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    yeniStok(val, index) {
      this.actionIndex = index
      if (val && val.yeni === true) {
        this.$bvModal.show('new')
      }
      const item = this.urun[index]
      item.miktar = val && val.adet != 0 ? 1 : ''
      item.birim = val && val.birim
    },
    fetchOptions(search) {
      store.dispatch('stokList', {
        searchKey: search,
        uzunluk: 20,
        baslangic: 0,
      })
    },
    repeateAgain() {
      this.urun.push({
        id: this.nextTodoId += this.nextTodoId,
        stokAdi: '',
        miktar: 0,
        birim: 'Adet',
      })
    },
    reset() {
      this.urun = [{
        id: 1,
        stokAdi: '',
        miktar: 0,
        birim: 'Adet',
      }]
    },
    newReset() {
      this.addItem.name = ''
      this.addItem.grupID = ''
      this.addItem.adet = ''
      this.addItem.birim = 'Adet'
      this.addItem.birimFiyat = ''
      this.addItem.kdv = ''
      this.addItem.alisFiyat = ''
      this.addItem.stoksuzSatis = false
      this.addItem.barkod = ''
    },
    newHide() {
      this.urun[this.actionIndex].stokAdi = ''
      this.$bvModal.hide('new')
      const t = this
      t.addItem.name = ''
      t.addItem.grupID = ''
      t.addItem.adet = ''
      t.addItem.birim = 'Adet'
      t.addItem.birimFiyat = ''
      t.addItem.kdv = ''
      t.addItem.alisFiyat = ''
      t.addItem.stoksuzSatis = false
      t.addItem.barkod = ''
    },
    add() {
      store.dispatch('stokSave', {
        stokAdi: this.addItem.name,
        stokGrupID: this.addItem.grupID,
        adet: this.addItem.adet,
        birim: this.addItem.birim,
        birimFiyat: this.addItem.birimFiyat.replace(',', '.'),
        kdv: this.addItem.kdv,
        alisFiyat: this.addItem.alisFiyat.replace(',', '.'),
        stoksuzSatis: this.addItem.stoksuzSatis,
        barkod: this.addItem.barkod,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.urun[this.actionIndex].stokAdi = res.data.stokAdi
              this.urun[this.actionIndex].stokID = res.data.stokID
              this.urun[this.actionIndex].birim = res.data.birim
              this.urun[this.actionIndex].miktar = res.data.adet
              this.newReset()
              this.$bvModal.hide('new')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: res.result.errMsg,
                  icon: 'ThumbsDownIcon',
                  variant: 'warning',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newGroupCancel() {
      this.addItem.grupID = ''
      this.$bvModal.show('new')
      this.grupName = ''
      this.isNewGroup = false
    },
    grupSave(islem) {
      store.dispatch('stokGroupSave', {
        adi: islem === 'kayit' ? this.grupName : '',
        islem,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.addItem.grupID = res.data.stokGrupID
              this.$bvModal.show('new')
              this.$refs.newGrup.hide()
              this.grupName = ''
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: res.result.errMsg,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.addItem.stokGrupID = ''
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-table'].showLoading = false
      }, 1000)
    },
  },
  created() {
    this.getUyeler()
    this.getKomisyonSema()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    store.dispatch('depoDetayStok', {
      baslangic: 0,
      uzunluk: this.firstPerPage,
      searchKey: '',
      depoID: this.id,
    })
        .then(res => {
          this.firstTotalRows = res.toplamKayit
        })
    store.dispatch('stokList', {
      uzunluk: 20,
      baslangic: 0,
    })
    if (Object.keys(store.getters.DEPO_LIST_GET).length === 0) {
      store.dispatch('depoListesi')
          .then(res => {
            const depo = Object.values(res)
                .find(item => item.depoID === this.id)
            this.depoName = depo.depoAdi
            this.depoID = depo.depoID
          })
    } else {
      const depo = Object.values(store.getters.DEPO_LIST_GET)
          .find(item => item.depoID === this.id)
      this.depoName = depo.depoAdi
      this.depoID = depo.depoID
    }
    store.dispatch('stokGrup')
  },
  beforeDestroy() {
    store.commit('DEPO_DETAY_AKTARIM_DETAY_ACTION', 'remove')
    store.commit('DEPO_DETAY_TARIH_FILTRE_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.depo-detay-stok-list .table thead th, table tbody td {
  padding: 0.4rem !important;
}
</style>
<style scoped>
.form-group {
  margin-bottom: 0;
}

.form-control {
  height: 31px;
}

#sidebar-form {
  padding: 5%;
}

.search-input {
  border-color: #c3baba !important;
}

.search-bar {
  width: 20% !important;
}

</style>
